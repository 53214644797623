import React from 'react'

function CompaniesIcon() {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.1513 2.61597H2.16976C1.48059 2.61597 0.921875 3.17465 0.921875 3.86382V10.0676C0.921875 10.7568 1.48056 11.3155 2.16976 11.3155H13.1513C13.8405 11.3155 14.3992 10.7569 14.3992 10.0676V3.86382C14.3992 3.17465 13.8404 2.61597 13.1513 2.61597ZM2.91483 5.25371C2.91483 4.8329 3.25595 4.49175 3.6768 4.49175H4.40598C4.82679 4.49175 5.16794 4.83287 5.16794 5.25371V5.9829C5.16794 6.40371 4.82683 6.74486 4.40598 6.74486H3.6768C3.25599 6.74486 2.91483 6.40375 2.91483 5.9829V5.25371ZM6.67393 9.30515C6.67393 9.42296 6.57843 9.51843 6.46066 9.51843H3.17215C3.05438 9.51843 2.95884 9.42292 2.95884 9.30515V8.65633C2.95884 8.53856 3.05438 8.44306 3.17215 8.44306H6.46069C6.57846 8.44306 6.67397 8.53856 6.67397 8.65633L6.67393 9.30515ZM12.3621 9.30515C12.3621 9.42296 12.2666 9.51843 12.1488 9.51843H8.86033C8.74256 9.51843 8.64701 9.42292 8.64701 9.30515V8.65633C8.64701 8.53856 8.74256 8.44306 8.86033 8.44306H12.1488C12.2666 8.44306 12.3621 8.53856 12.3621 8.65633V9.30515Z" fill="white" />
      <path d="M16.1942 0H5.21273C4.52356 0 3.96484 0.558681 3.96484 1.24789V1.58501H13.1517C14.4082 1.58501 15.4305 2.60728 15.4305 3.86378V4.82869H17.4422V1.24789C17.4422 0.558681 16.8834 0 16.1942 0Z" fill="white" />
      <path d="M15.4297 8.69964H16.1934C16.8826 8.69964 17.4414 8.14096 17.4414 7.45171V6.06592H15.4297V8.69964Z" fill="white" />
    </svg>

  )
}

export default CompaniesIcon