import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, Drawer, useMediaQuery } from "@mui/material";
import { Logo } from "./logo";
import { NavItem } from "./nav-item";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {useSelector} from "react-redux"

// import DashboardIcon from './sectionsIcon/DashboardIcon';
import CompaniesIcon from "./sectionsIcon/CompaniesIcon";
import UsersIcon from "./sectionsIcon/UsersIcon";
import ReportsIcon from "./sectionsIcon/ReportsIcon";
import BlogsIcon from "./sectionsIcon/BlogsIcon";
// import IndustriesCategoriesIcon from './sectionsIcon/IndustriesCategoriesIcon';
// import Reactions from "../pages/reactions/Reactions";
import Smile from "../icons/Smile";
import Award from "./sectionsIcon/Award";
import { getUserInfo } from "../store/adminActions";

import DashboarSvg from "../icons/DashboarSvg";
import AdminSvg from "../icons/AdminSvg";
import SupportAgentTwoToneIcon from "@mui/icons-material/SupportAgentTwoTone";
import QaSvg from "../icons/QaSvg";

const sections = [
  {
    name: "Dashboard",
    icon: <DashboarSvg />,
    href: "/",
    id: 1,
  },
  {
    name: "Companies",
    icon: <CompaniesIcon />,
    href: "/companies",
    id: 2,
  },
  {
    name: "Users",
    icon: <UsersIcon />,
    href: "/users",
    id: 3,
  },
  // {
  //   name: "Roles",
  //   icon: <RolesIcons />,
  //   href: "/roles",
  //   id: 4
  // },
  {
    name: "Reports",
    icon: <ReportsIcon />,
    href: "/reports",
    id: 5,
  },
  {
    name: "Blogs",
    icon: <BlogsIcon />,
    href: "/blogs",
    id: 6,
  },
  {
    name: "Reactions",
    icon: <Smile />,
    href: "/reactions",
    id: 7,
  },
  {
    name: "Awards",
    icon: <Award />,
    href: "/award",
    id: 8,
  },
  // {
  //   name: "Moderation",
  //   icon: <AmbasadorSvg />,
  //   href: "/moderation",
  //   id: 9
  // },

  // {
  //   name: "Categories & Industries",
  //   icon: <CategoriesSvg />,
  //   href: "/industries-category",
  //   id: 10
  // },
  // {
  //   name: "Ambassador",
  //   // icon: <IndustriesCategoriesIcon />,
  //   href: "/ambassador",
  //   id: 11
  // },
  {
    name: "Admins",
    icon: <AdminSvg />,
    href: "/admins",
    id: 12,
  },
  {
    name: "ReviewPage",
    icon: <AdminSvg />,
    href: "/reviewpage",
    id: 13,
  },
  {
    name: "Support",
    icon: <SupportAgentTwoToneIcon />,
    href: "/support",
    id: 14,
  },
  {
    name: "Questions & Anwers",
    icon: <QaSvg />,
    href: "/Qa",
    id: 15,
  },
  ,
  {
    name: "Events",
    icon: <QaSvg />,
    href: "/events",
    id: 16,
  },
  {
    name: "Dump Companies",
    icon: <AdminSvg />,
    href: "/dump-companies",
    id: 17,
  }
];

export const DashboardSidebar = (props) => {
  const { open, onClose } = props;
  // const router = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.adminActions);

  // const {route} = useSelector(state => state.navigation)

  useEffect(() => {
    if (auth) {
      dispatch(getUserInfo());
    }
  }, [auth]);

  // useEffect(
  //   () => {
  //     if (!router.isReady) {
  //       return;
  //     }

  //     if (open) {
  //       onClose?.();
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [router.asPath]
  // );

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Box sx={{ p: 3 }}>
            <Link to="/">
              <Logo
                sx={{
                  height: 42,
                  width: 42,
                }}
              />
            </Link>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: "#2D3748",
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {sections.map((item) => (
            <span key={item.name}>
              <NavItem
                icon={item.icon}
                href={item.href}
                title={item.name}
                id={item.id}
              />
            </span>
          ))}
        </Box>

        <Divider sx={{ borderColor: "#2D3748" }} />
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
