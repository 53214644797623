import React from 'react'

function Users() {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8474 10.1374L8.4318 9.00853C8.20398 8.90193 8.06238 8.68778 8.06238 8.44953V7.65038C8.12028 7.5842 8.18122 7.5088 8.24418 7.4256C8.55748 7.01196 8.80857 6.55153 8.99113 6.05493C9.34792 5.902 9.57954 5.57227 9.57954 5.2V4.25455C9.57954 4.02693 9.48851 3.8064 9.32668 3.63409V2.37687C9.34084 2.24687 9.39647 1.47302 8.79769 0.8346C8.27832 0.2808 7.43553 0 6.29235 0C5.14917 0 4.30639 0.2808 3.78701 0.834364C3.18824 1.47278 3.24387 2.24687 3.25803 2.37664V3.63385C3.0962 3.80616 3.00517 4.02669 3.00517 4.25431V5.19976C3.00517 5.48742 3.145 5.75569 3.3837 5.93462C3.61532 6.79191 4.10005 7.43789 4.26947 7.64518V8.42731C4.26947 8.65611 4.13596 8.866 3.92128 8.97567L1.66551 10.1258C0.931964 10.5002 0.476562 11.2173 0.476562 11.9983V13H12.1081V12.0444C12.1081 11.2315 11.6249 10.5009 10.8474 10.1374Z" fill="white" />
      <path d="M14.4414 10.4401L12.1071 9.458C12.0519 9.43118 11.9907 9.36563 11.9381 9.27815L13.5046 9.27699C13.5046 9.27699 13.5951 9.28562 13.7355 9.28562C13.9931 9.28562 14.3688 9.25716 14.6958 9.12069C14.892 9.03858 15.0379 8.87646 15.0965 8.67585C15.1556 8.47337 15.1181 8.25899 14.9942 8.08731C14.5465 7.4682 13.5015 5.85047 13.4688 4.20965C13.4681 4.18142 13.3735 1.39104 10.5375 1.36841C10.2525 1.37074 9.98318 1.4048 9.72798 1.46475C9.91812 1.95322 9.90059 2.39037 9.88763 2.5322V3.63627C10.0432 3.85134 10.1277 4.10677 10.1277 4.36897V5.30205C10.1277 5.7469 9.88667 6.15862 9.50183 6.38956C9.32225 6.86124 9.08458 7.30188 8.79385 7.70078C8.75784 7.75046 8.72231 7.79735 8.6875 7.84167V8.50883C8.6875 8.61193 8.74416 8.70127 8.83899 8.74723L11.1324 9.86133C11.9936 10.2798 12.5284 11.1208 12.5284 12.0564V13H15.5296V12.1376C15.5296 11.4163 15.1172 10.7683 14.4414 10.4401Z" fill="white" />
    </svg>

  )
}

export default Users