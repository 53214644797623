import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const Logo = styled((props) => {
  // const { variant, ...other } = props;

  // const color = variant === 'light' ? '#C1C4D6' : '#5048E5';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="199" height="29" viewBox="0 0 199 29" fill="none">
      <path d="M45.8996 19.5999C45.8996 16.9999 44.9996 14.7999 43.1996 12.9999C41.3996 11.1999 39.0996 10.3999 36.2996 10.3999C34.7996 10.3999 33.3996 10.6999 32.0996 11.1999V14.9999H27.7996C26.9996 16.3999 26.5996 17.8999 26.5996 19.5999C26.5996 22.2999 27.4996 24.4999 29.2996 26.2999C31.0996 27.9999 33.4996 28.8999 36.4996 28.8999C38.4996 28.8999 40.1996 28.4999 41.7996 27.6999C43.2996 26.8999 44.4996 25.7999 45.2996 24.3999L40.6996 22.4999C39.7996 23.6999 38.3996 24.2999 36.4996 24.2999C34.1996 24.2999 32.6996 23.2999 31.9996 21.3999H45.6996C45.8996 20.9999 45.8996 20.3999 45.8996 19.5999ZM32.1996 17.8999C32.7996 15.9999 34.1996 14.9999 36.3996 14.9999C37.3996 14.9999 38.2996 15.1999 38.9996 15.6999C39.6996 16.1999 40.1996 16.8999 40.4996 17.7999L32.1996 17.8999Z" fill = {
    true ? "white" : "#231F20"} />
      <path d ="M62.0995 19.2999C61.1995 18.3999 59.7995 17.7999 57.8995 17.3999L56.0995 16.9999C55.2995 16.8999 54.7995 16.6999 54.3995 16.4999C54.0995 16.2999 53.8995 16.0999 53.8995 15.7999C53.8995 15.0999 54.4995 14.7999 55.5995 14.7999C57.4995 14.7999 59.2995 15.2999 60.8995 16.2999L62.7995 12.3999C60.8995 11.0999 58.5995 10.3999 55.6995 10.3999C53.5995 10.3999 51.9995 10.8999 50.6995 11.9999C49.3995 13.0999 48.7995 14.4999 48.7995 16.1999C48.7995 18.9999 50.5995 20.7999 54.1995 21.4999L56.1995 21.8999C56.9995 22.0999 57.4995 22.1999 57.7995 22.3999C58.0995 22.5999 58.1995 22.7999 58.1995 23.0999C58.1995 23.8999 57.4995 24.2999 56.0995 24.2999C53.9995 24.2999 52.1995 23.6999 50.4995 22.2999L47.9995 26.2999C48.8995 26.9999 49.9995 27.5999 51.4995 28.1999C52.9995 28.7999 54.4995 28.9999 55.9995 28.9999C58.2995 28.9999 60.0995 28.3999 61.3995 27.2999C62.6995 26.1999 63.3995 24.6999 63.3995 22.9999C63.3995 21.3999 62.9995 20.1999 62.0995 19.2999Z" fill={ true? "white" : "#231F20" } />
      <path d=" M77.6995 23.1C77.2995 23.5 76.6995 23.7 75.9995 23.7C74.5995 23.7 73.8995 23 73.8995 21.6V15.4H78.9995V10.8H73.8995V6.19995H68.2995V8.79995C68.2995 9.59995 68.0995 10.1 67.8995 10.4C67.5995 10.7 67.0995 10.8 66.2995 10.8H65.4995V15.4H68.2995V22C68.2995 24.1 68.8995 25.8 69.9995 26.9C71.1995 28.1 72.7995 28.7 74.7995 28.7C76.9995 28.7 78.6995 28.2 79.8995 27.2L77.6995 23.1Z" fill={
        true ? "white" : "#231F20"} />
          <path d ="M100.8 19.5999C100.8 16.9999 99.8996 14.7999 98.0996 12.9999C96.2996 11.1999 93.9996 10.3999 91.1996 10.3999C88.3996 10.3999 86.0996 11.2999 84.2996 12.9999C82.4996 14.7999 81.5996 16.9999 81.5996 19.5999C81.5996 22.2999 82.4996 24.4999 84.2996 26.2999C86.0996 27.9999 88.4996 28.8999 91.4996 28.8999C93.3996 28.8999 95.1996 28.4999 96.6996 27.6999C98.2996 26.8999 99.3996 25.7999 100.2 24.3999L95.5996 22.4999C94.6996 23.6999 93.2996 24.2999 91.4996 24.2999C89.1996 24.2999 87.6996 23.2999 86.9996 21.3999H100.7C100.8 20.9999 100.8 20.3999 100.8 19.5999ZM87.0996 17.8999C87.5996 15.9999 88.9996 14.9999 91.1996 14.9999C92.1996 14.9999 93.0996 15.1999 93.7996 15.6999C94.4996 16.1999 94.9996 16.8999 95.2996 17.7999L87.0996 17.8999Z" fill={ true ? "white" : "#231F20" } />
          <path d=" M117.5 3.19995V12.2C116.3 11 114.5 10.4 112.3 10.4C109.8 10.4 107.7 11.3 106.1 13.1C104.5 14.9 103.6 17.1 103.6 19.7C103.6 22.3 104.4 24.5 106.1 26.2999C107.8 28.0999 109.9 29 112.3 29C114.7 29 116.6 28.2999 118 26.7999V28.6H123.2V3.19995H117.5ZM116.5 22.7999C115.8 23.5999 114.7 24 113.4 24C112.2 24 111.2 23.5999 110.4 22.7999C109.6 22 109.2 20.9 109.2 19.7C109.2 18.4 109.6 17.4 110.4 16.6C111.2 15.8 112.2 15.4 113.4 15.4C114.8 15.4 115.8 15.8 116.5 16.6C117.2 17.4 117.6 18.4999 117.6 19.7999C117.6 21 117.2 22 116.5 22.7999Z" fill={
            true ? "white" : "#231F20"} />
              <path d ="M152.7 10.5999L147.2 24.6999L141.7 10.5999H139.9L134.3 24.6999L128.8 10.5999H126.2L133.4 28.5999H135.2L140.8 14.4999L146.3 28.5999H148.2L155.4 10.5999H152.7Z" fill={ true ? "white" : "#231F20" } />
              <path d=" M175.3 19.8C175.3 17.2 174.5 15 172.8 13.3C171.2 11.5 169 10.7 166.5 10.7C164 10.7 161.9 11.6 160.2 13.3C158.5 15.1 157.7 17.2 157.7 19.8C157.7 22.4 158.6 24.6 160.2 26.3C161.9 28.1 164.1 28.9 166.8 28.9C168.5 28.9 170.1 28.5 171.5 27.7C172.9 26.9 174 25.9 174.8 24.6L172.8 23.4C171.5 25.5 169.4 26.5 166.8 26.5C165 26.5 163.5 26 162.2 24.9C161 23.8 160.3 22.4 160.1 20.7H175.2L175.3 19.8ZM160.2 18.6C160.4 16.9 161.2 15.5 162.3 14.5C163.5 13.4 164.9 12.9 166.5 12.9C168.1 12.9 169.6 13.4 170.7 14.5C171.9 15.6 172.6 16.9 172.8 18.6H160.2Z" fill={true ? "white" : "#231F20"} />
                  <path d ="M195.9 13.6C194.2 11.9 192.1 11 189.5 11C186.6 11 184.3 12.1 182.8 14.2V3.19995H180.3V28.6H182.7V25.7C184.2 27.9 186.4 29 189.4 29C192 29 194.1 28.1 195.7 26.4C197.4 24.6999 198.2 22.6 198.2 20.1C198.4 17.5 197.6 15.4 195.9 13.6ZM194 24.7C192.8 26 191.2 26.6 189.4 26.6C187.5 26.6 186 26 184.7 24.7C183.5 23.5 182.9 21.9 182.9 20C182.9 18.1 183.5 16.5 184.7 15.3C185.9 14.1 187.5 13.4 189.4 13.4C191.2 13.4 192.8 14 194 15.3C195.2 16.6 195.9 18.1 195.9 20C195.9 21.9 195.3 23.5 194 24.7Z" fill={ true ? "white" : "#231F20" } />
                  <path d=" M29 5.9V11.8H21.8L15.9 27.8C15.7 28.5 15 28.9 14.3 28.9H4.6L0 18.2H7.3L9.5 23.3C9.6 23.7 10.1 23.6 10.3 23.3L14.5 11.8H11.6V5.9H15.2C16.1 5.9 16.8 5.4 17.2 4.5L18.9 0H26.2L24 5.9H29Z" fill="#18ED7D" />
    </svg >
  );
})``;

Logo.defaultProps = {
  variant: 'primary'
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary'])
};
