import React from 'react'

function BellIcon() {
  return (
    <svg width="38" height="35" viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="38" height="35" rx="10" fill="#AAF8CF" />
    <path d="M29.3532 24.983C27.0153 23.9272 26.9263 19.3953 26.9246 19.2809V16.2405C26.9246 13.3143 25.1365 10.7981 22.5955 9.72667C22.5901 8.22222 21.3647 7 19.8592 7C18.3536 7 17.1283 8.2223 17.1228 9.7266C14.5819 10.798 12.7938 13.3142 12.7938 16.2404V19.2808C12.7921 19.3952 12.7031 23.9271 10.3651 24.9829C10.0988 25.1033 9.9524 25.3932 10.014 25.6789C10.0755 25.9646 10.3282 26.1687 10.6205 26.1687H16.5475C16.669 26.8371 16.9851 27.459 17.4685 27.9626C18.1105 28.6315 18.9595 28.9999 19.8591 28.9999C20.7587 28.9999 21.6077 28.6315 22.2497 27.9626C22.7331 27.4589 23.0492 26.8371 23.1706 26.1687H29.0977C29.39 26.1687 29.6426 25.9647 29.7042 25.6789C29.7658 25.3932 29.6195 25.1033 29.3532 24.983ZM26.2734 22.812C26.5192 23.5571 26.8672 24.3054 27.3607 24.9281H12.3576C12.851 24.3054 13.199 23.5572 13.4448 22.812H26.2734ZM19.8592 8.24074C20.5402 8.24074 21.116 8.69835 21.2962 9.32212C20.832 9.22585 20.3514 9.17509 19.8592 9.17509C19.3669 9.17509 18.8863 9.22581 18.4222 9.32212C18.6024 8.69839 19.1782 8.24074 19.8592 8.24074ZM14.0345 19.2846V16.2404C14.0345 13.0287 16.6474 10.4158 19.8592 10.4158C23.0709 10.4158 25.6838 13.0287 25.6838 16.2404V19.2897C25.6847 19.383 25.6992 20.3638 25.9464 21.5711H13.7718C14.0192 20.3626 14.0338 19.3802 14.0345 19.2846ZM19.8592 27.7593C18.9123 27.7593 18.0859 27.0766 17.8206 26.1688H21.8977C21.6324 27.0766 20.8061 27.7593 19.8592 27.7593Z" fill="#007332" />
    <path d="M19.8592 11.2863C17.1901 11.2863 15.0186 13.4577 15.0186 16.1268C15.0186 16.4694 15.2963 16.7472 15.6389 16.7472C15.9816 16.7472 16.2593 16.4694 16.2593 16.1268C16.2593 14.1418 17.8742 12.527 19.8592 12.527C20.2018 12.527 20.4796 12.2492 20.4796 11.9066C20.4796 11.564 20.2018 11.2863 19.8592 11.2863Z" fill="#007332" />
  </svg>
  )
}

export default BellIcon;