import React from 'react';

const SearchIconHeader = () => {
    return (
        <svg style={{ marginLeft: "10px", marginTop: "7px" }} width="18" height="18" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.996338 9.98841C0.996338 15.4987 5.47932 19.9768 10.9847 19.9768C13.5212 19.9768 15.8413 19.0232 17.606 17.4601L23.9716 23.8257C24.0896 23.9437 24.242 24.0027 24.3993 24.0027C24.5566 24.0027 24.709 23.9437 24.8269 23.8257C25.0629 23.5897 25.0629 23.2113 24.8269 22.9753L18.4564 16.6097C20.0195 14.845 20.9732 12.5297 20.9732 9.98841C20.9732 4.47807 16.4902 0 10.9847 0C5.47932 0 0.996338 4.47807 0.996338 9.98841ZM19.7688 9.98841C19.7688 14.8302 15.8266 18.7725 10.9847 18.7725C6.14292 18.7725 2.20065 14.8302 2.20065 9.98841C2.20065 5.14659 6.14292 1.20431 10.9847 1.20431C15.8266 1.20431 19.7688 5.14167 19.7688 9.98841Z" fill="#B3B7BB" />
        </svg>
    )
}

export default SearchIconHeader;