import { createSlice } from "@reduxjs/toolkit";




const createdAward = createSlice({
  name: "createdAward",

  
  initialState: {
    awardDetails: {},
    edit: false,
    createdAwardId: 0
  },
  reducers: {
    getAwardDetail: (state, action) => {
      state.awardDetails[action.payload.name] = action.payload.val
    },
    resetData: (state) => {
      state.awardDetails = {}
      state.createdAwardId = 0
    },
    getCreatedAwardId: (state,action) => {
      state.createdAwardId = action.payload
    },
    changeAwardCreateMethod: (state,action) => {
      state.edit = action.payload
    },
    redirectEdit: (state,action) => {
      
      state.awardDetails = action.payload
    }
  }
})

export const { getAwardDetail, redirectEdit, resetData, getCreatedAwardId, changeAwardCreateMethod } = createdAward.actions;

export default createdAward.reducer