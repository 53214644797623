// @/src/common/axiosPrivate.js
import axios from "axios";

import { memoizedRefreshToken } from "./refreshtoken";

const api = process.env.REACT_APP_API_URL;
const version = process.env.REACT_APP_API_VERSION;

const api_url = api + version;
axios.defaults.baseURL = api_url;

axios.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("access");

    if (accessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${accessToken}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 403) {
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      
      window.location.reload()
    }

    if (
      (error?.response?.status === 401) &&
      !config?.sent
    ) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      let refreshToken = localStorage.getItem("refresh")
      if (result?.access) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${refreshToken}`,
        };
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
);

export const privateAxios = axios;
