import {Link, useLocation} from "react-router-dom"


import { Box, Button, ListItem } from '@mui/material';

export const NavItem = (props) => {
  const { href, icon, title,  } = props;
  const router = useLocation();
  const active = href ? (router.pathname === href) : false;



  


  // const handleGetMethods = async (id) => {
  //   let getMethod = await axios.get(`/get-allowed-actions/${id}`)
  //   let data = await getMethod.data

   
  // }

  
  
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        // py: 0,
        // px: 2,
        mt: 1,
      }}
      
    >
      <Link
        to={props.href}
        style={{ 
          textDecoration: "none",
          width:"100%", 
          transition: "0.4s",
          backgroundColor: active ? "white" : "transparent",
          marginLeft: "10px",
          borderTopLeftRadius: "42px" ,
          borderBottomLeftRadius: "42px",
          
        }}
      >
        <Button
          disableRipple
          sx={{
            backgroundColor: false && 'rgba(255,255,255, 0.08)',
            borderRadius: 1,
            color: false ? 'secondary.main' : 'neutral.300',
            fontWeight: false && 'fontWeightBold',
            justifyContent: 'flex-start',
            px: 3,
            textAlign: 'left',
            textTransform: 'none',
            textDecoration: 'none',
            width: '100%',
            '& .MuiButton-startIcon': {
              color: false ? 'secondary.main' : 'neutral.400'
            },
          }}
        >
          <span className={`iconWrapper ${active ? "linkActive": ""}`} >
            {icon}
         </span>
          <Box sx={{ flexGrow: 1, color: active ? "#20BC63" : "white", }}>
            {title}
          </Box>
        </Button>
      </Link>
    </ListItem>
  );
};

