import { configureStore } from "@reduxjs/toolkit";
import navigation from "./navigation"
import adminActions from "./adminActions";
import countries from "./countries";
import createdAward from "./createAward"
import triggerCompanyData from "./triggerCompanyData";
import priceRanges from "./priceRanges"
import toastifyListener from "./toastifyListener";

export default configureStore({
    reducer: {
      navigation,
      adminActions,
      countries,
      createdAward,
      triggerCompanyData,
      priceRanges,
      toastifyListener
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
  });



