import React from 'react'

function AdminSvg() {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6391 21.9172H1.33073C1.02131 21.9172 0.724564 21.7943 0.505771 21.5755C0.286979 21.3567 0.164063 21.06 0.164062 20.7506V16.1422L0.747396 15.7922C3.04775 14.4473 5.66607 13.7423 8.33073 13.7506C9.81216 13.7553 11.2853 13.9714 12.7057 14.3922C12.5192 15.2353 12.4214 16.0955 12.4141 16.9589C12.4175 18.6862 12.8377 20.3871 13.6391 21.9172Z" fill="white"/>
    <path d="M8.33008 11.417C9.36843 11.417 10.3835 11.1091 11.2468 10.5322C12.1102 9.95533 12.7831 9.1354 13.1804 8.17608C13.5778 7.21677 13.6818 6.16117 13.4792 5.14277C13.2766 4.12437 12.7766 3.18891 12.0424 2.45468C11.3082 1.72046 10.3727 1.22044 9.3543 1.01787C8.3359 0.815299 7.2803 0.919267 6.32099 1.31663C5.36168 1.71399 4.54174 2.38689 3.96486 3.25025C3.38799 4.11361 3.08008 5.12864 3.08008 6.16699C3.08008 7.55938 3.6332 8.89474 4.61777 9.8793C5.60233 10.8639 6.93769 11.417 8.33008 11.417Z" fill="white"/>
    <path d="M25.8307 17.892V16.6087L23.7307 16.0837L23.4391 15.442L24.6057 13.5753L23.6724 12.642L21.8057 13.8087L21.1641 13.517L20.6391 11.417H19.3557L18.8307 13.517L18.1891 13.8087L16.3224 12.642L15.3891 13.5753L16.5557 15.442L16.2641 16.0837L14.1641 16.6087V17.892L16.2641 18.417L16.5557 19.0587L15.3891 20.9253L16.3224 21.8587L18.1891 20.692L18.8307 20.9837L19.3557 23.0837H20.6391L21.1641 20.9837L21.8057 20.692L23.6724 21.8587L24.6057 20.9253L23.4391 19.0587L23.7307 18.417L25.8307 17.892ZM19.9974 19.0003C19.6513 19.0003 19.3129 18.8977 19.0251 18.7054C18.7374 18.5131 18.5131 18.2398 18.3806 17.92C18.2482 17.6003 18.2135 17.2484 18.281 16.9089C18.3485 16.5695 18.5152 16.2576 18.76 16.0129C19.0047 15.7681 19.3165 15.6015 19.656 15.534C19.9955 15.4664 20.3473 15.5011 20.6671 15.6335C20.9869 15.766 21.2602 15.9903 21.4525 16.2781C21.6448 16.5659 21.7474 16.9042 21.7474 17.2503C21.7555 17.4823 21.7159 17.7135 21.6308 17.9295C21.5457 18.1455 21.4171 18.3417 21.253 18.5059C21.0888 18.67 20.8926 18.7987 20.6766 18.8837C20.4606 18.9688 20.2294 19.0085 19.9974 19.0003Z" fill="white"/>
    </svg>
    
  )
}

export default AdminSvg