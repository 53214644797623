import React from 'react'

function ReportsIcon() {
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2086 0H3.78839C3.35131 0 2.99696 0.332617 2.99696 0.742891V2.76286H0.791424C0.354347 2.76286 0 3.09548 0 3.50575V19.2571C0 19.6674 0.354347 20 0.791424 20H13.2425C13.6796 20 14.034 19.6674 14.034 19.2571V17.6125H16.2086C16.6457 17.6125 17 17.2799 17 16.8697V0.742891C17 0.332617 16.6457 0 16.2086 0ZM10.9881 14.8334H3.04572C2.60864 14.8334 2.25429 14.5008 2.25429 14.0906C2.25429 13.6803 2.60864 13.3477 3.04572 13.3477H10.9882C11.4253 13.3477 11.7797 13.6803 11.7797 14.0906C11.7797 14.5008 11.4252 14.8334 10.9881 14.8334ZM10.9881 10.0139H3.04572C2.60864 10.0139 2.25429 9.68125 2.25429 9.27098C2.25429 8.8607 2.60864 8.52809 3.04572 8.52809H10.9882C11.4253 8.52809 11.7797 8.8607 11.7797 9.27098C11.7797 9.68125 11.4252 10.0139 10.9881 10.0139ZM10.9881 6.27861H3.04572C2.60864 6.27861 2.25429 5.946 2.25429 5.53572C2.25429 5.12545 2.60864 4.79283 3.04572 4.79283H10.9882C11.4253 4.79283 11.7797 5.12545 11.7797 5.53572C11.7797 5.946 11.4252 6.27861 10.9881 6.27861ZM15.4172 16.1268H14.034V3.50575C14.034 3.09548 13.6796 2.76286 13.2425 2.76286H4.57981V1.48578H15.4172V16.1268Z" fill="white" />
    </svg>

  )
}

export default ReportsIcon