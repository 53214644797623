import React from 'react'

function DashboarSvg() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.888889 8.88889H6.22222C6.45797 8.88889 6.68406 8.79524 6.85076 8.62854C7.01746 8.46184 7.11111 8.23575 7.11111 8V0.888889C7.11111 0.653141 7.01746 0.427048 6.85076 0.260349C6.68406 0.0936505 6.45797 0 6.22222 0H0.888889C0.653141 0 0.427048 0.0936505 0.260349 0.260349C0.0936505 0.427048 0 0.653141 0 0.888889V8C0 8.23575 0.0936505 8.46184 0.260349 8.62854C0.427048 8.79524 0.653141 8.88889 0.888889 8.88889ZM0 15.1111C0 15.3469 0.0936505 15.573 0.260349 15.7397C0.427048 15.9064 0.653141 16 0.888889 16H6.22222C6.45797 16 6.68406 15.9064 6.85076 15.7397C7.01746 15.573 7.11111 15.3469 7.11111 15.1111V11.5556C7.11111 11.3198 7.01746 11.0937 6.85076 10.927C6.68406 10.7603 6.45797 10.6667 6.22222 10.6667H0.888889C0.653141 10.6667 0.427048 10.7603 0.260349 10.927C0.0936505 11.0937 0 11.3198 0 11.5556V15.1111ZM8.88889 15.1111C8.88889 15.3469 8.98254 15.573 9.14924 15.7397C9.31594 15.9064 9.54203 16 9.77778 16H15.1111C15.3469 16 15.573 15.9064 15.7397 15.7397C15.9064 15.573 16 15.3469 16 15.1111V8.88889C16 8.65314 15.9064 8.42705 15.7397 8.26035C15.573 8.09365 15.3469 8 15.1111 8H9.77778C9.54203 8 9.31594 8.09365 9.14924 8.26035C8.98254 8.42705 8.88889 8.65314 8.88889 8.88889V15.1111ZM9.77778 6.22222H15.1111C15.3469 6.22222 15.573 6.12857 15.7397 5.96187C15.9064 5.79517 16 5.56908 16 5.33333V0.888889C16 0.653141 15.9064 0.427048 15.7397 0.260349C15.573 0.0936505 15.3469 0 15.1111 0H9.77778C9.54203 0 9.31594 0.0936505 9.14924 0.260349C8.98254 0.427048 8.88889 0.653141 8.88889 0.888889V5.33333C8.88889 5.56908 8.98254 5.79517 9.14924 5.96187C9.31594 6.12857 9.54203 6.22222 9.77778 6.22222Z" fill="white"/>
    </svg>
    
  )
}

export default DashboarSvg