import { createSlice } from "@reduxjs/toolkit";

export const trigger = createSlice({
  name: "trigger",
  initialState: {
    sync: false
  },
  reducers: {
    triggerFetchPossibleCompany:(state,action) => {
      state.sync = action.payload
    }
  },
});

export const { triggerFetchPossibleCompany } = trigger.actions;
export default trigger.reducer;
