import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { AppBar, Avatar, IconButton, Toolbar, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIconHeader from '../icons/SearchIconHeader';
import  BellIcon  from '../icons/bell';
import { useSelector } from "react-redux"
import { useLocation } from 'react-router-dom';
import Select from "react-select";
import style from '../components/dashboardNavbar.module.scss';


const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, ...other } = props;

  const { userInfo } = useSelector(state => state.adminActions)

  const location = useLocation()

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
      fontFamily: "Epiloge-Regular",
      fontSize: "16px",
      alignItems: "center",
      marginTop: "3px",
      display: "flex",
    }),
    option: (styles) => ({
      ...styles,
      cursor: "pointer",
      minHeight: "55px",
      width: "100%",
      position: "relative",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "27px",
      color: "#4B4B4B",
      fontFamily: "Epiloge-Regular",
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
      width: "100%",
      top: "50px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };


  const handleLogout = () => {
    localStorage.clear()
    window.location.reload();
  }


  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280
          },
          width: {
            lg: 'calc(100% - 280px)'
          }
        }}
        {...other}>

        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          <h4 style={{ color: "black", margin: "0px 15px", textTransform: "capitalize" }} className="epiloge-medium">{
            location?.pathname == "/" ? "Dashboard" : location?.pathname.replace("/", "")
          }</h4>


          <div className={`${style.gryBoxLocation}`}>
            <div className={style.searchBox}>
              <SearchIconHeader />
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "rgba(237, 243, 253, 0.66)",
                    primary: "rgba(237, 243, 253, 0.66)",
                    neutral50: "#B4BBC1",
                  },
                })}
                isClearable
                type="text"
                placeholder="Search"
                styles={customStyles}
              />
            </div>
          </div>
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          {/* <Tooltip title="Search">
            <IconButton sx={{ ml: 1 }}>
              <SearchIcon fontSize="small" />
            </IconButton>
          </Tooltip> */}

          {/* <Box sx={{ flexGrow: 1 }} /> */}
    
          <Tooltip title="Notifications">
            <IconButton sx={{ ml: 0 }} >
              {/* <Badge
                badgeContent={4}
                color="primary"
                variant="dot"
              > */}
                <BellIcon className={style.bellIcon} fontSize="small" />
              {/* </Badge> */}
            </IconButton>
          </Tooltip>

          {/* <h4 style={{ color: "black", margin: "0px 15px" }} className="epiloge-medium">{userInfo?.full_name}</h4> */}
          <Avatar
            sx={{
              width: {
                lg: 40, md: 40, sm: 40, xs: 20,
              },
              height: {
                lg: 40, md: 40, sm: 40, xs: 20,
              },
              ml: 1,
            }}
            src={userInfo?.profile_picture}
          >
          </Avatar>
          <Tooltip title="log out" >
            <svg onClick={() => handleLogout()} className={style.logout}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 9v-4l8 7-8 7v-4h-8v-6h8zm-16-7v20h14v-2h-12v-16h12v-2h-14z" /></svg>
          </Tooltip>
        </Toolbar>
        
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func
};
