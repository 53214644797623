import React from 'react'

function BlogsIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8.18079V15.1897C0 16.1894 0.81044 16.9999 1.81019 16.9999C2.80994 16.9999 3.62038 16.1894 3.62038 15.1897V8.18079H0Z" fill="white" />
      <path d="M4.33047 0V7.47233H4.3304V15.1896C4.3304 15.9001 4.03347 16.5416 3.55859 17H15.1915H15.1916C16.1913 17 17.0018 16.1725 17.0018 15.1518V5.14925V3.90181V0H4.33047ZM6.05408 2.21431H10.2253V9.3259H6.05408V2.21431ZM15.524 14.9434H5.15685V14.2351H15.524V14.9434H15.524ZM15.524 12.5327H5.15685V11.8244H15.524V12.5327H15.524ZM15.524 7.98246H11.6282V7.27414H15.524V7.98246H15.524ZM15.524 5.80282H11.6282V5.0945H15.524V5.80282H15.524ZM15.524 3.62319H11.6282V2.91486H15.524V3.62319H15.524Z" fill="white" />
    </svg>

  )
}

export default BlogsIcon