// @/src/common/refreshToken.js
import mem from "mem";

import {publicAxios} from "./publicAxios"

const refreshTokenFn = async () => {
  const refreshToken = localStorage.getItem("refresh");

  try {
    const response = await publicAxios.post("/token/refresh", {
      refresh: refreshToken,
    });

    const { access, refresh } = response.data;

    

    if (!access) {
      localStorage.removeItem("access");
      localStorage.removeItem("refreh");
    }

    localStorage.setItem("access", access);
    localStorage.setItem("refresh", refresh);

    const session = {
      access,
      refresh,
    };
    return session;
  } catch (error) {
    
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
   
    window.location.reload()
  }
};



export const memoizedRefreshToken = mem(refreshTokenFn);
