import React, { Suspense, useEffect, lazy } from "react";
import "../src/assets/style/style.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from "react-router-dom";
import { theme } from "./theme/index";
import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import "react-image-crop/dist/ReactCrop.css";
import { DashboardLayout } from "./components/dashboard-layout";

const NotFound = lazy(() => import("./pages/notFound/NotFound"));
const Companies = lazy(() => import("./pages/companies/Companies"));
const Users = lazy(() => import("./pages/users/Users"));
const Reports = lazy(() => import("./pages/reportPage/Reports"));
const Roles = lazy(() => import("./pages/Roles/Roles"));
const Blog = lazy(() => import("./pages/blog/Blogs"));
const CreateBlog = lazy(() => import("./pages/blog/CreateBlog"));
const Industries = lazy(() => import("./pages/Industries/Industries"));
const Moderation = lazy(() => import("./pages/moderation/Moderation"));
const Dash = lazy(() => import("./pages/dashboard/Dash"));
const Profil = lazy(() => import("./pages/profil/Profil"));
const Creat = lazy(() => import("./pages/creat/Creat"));
const AmbassadorPage = lazy(() =>
  import("./pages/ambassadorPage/AmbassadorPage")
);
const Admins = lazy(() => import("./pages/admins/Admins"));
const ReviewPage = lazy(() => import("./pages/reviewPage/ReviewPage"));
const EditBlog = lazy(() => import("./pages/blog/EditBlog"));
const CreateAwardBlog = lazy(() =>
  import("./pages/award/CreateAward/CreateAwardBlog")
);
const Award = lazy(() => import("./pages/award/Awards"));
const Login = lazy(() => import("./pages/login/Login"));
const Reactions = lazy(() => import("./pages/reactions/Reactions"));
const CreateReactions = lazy(() => import("./pages/reactions/CreateReactions"));
const EditReactions = lazy(() => import("./pages/reactions/EditReactions"));
const Support = lazy(() => import("./pages/support/Support"));
const Qa = lazy(() => import("./pages/QA/QA"));
const Events = lazy(() => import("./pages/events/Events"));
const CreateEvent = lazy(() => import("./pages/events/CreateEvent"));
const EditEvent = lazy(() => import("./pages/events/EditEvent"));
const EventView = lazy(() => import("./pages/events/EventView"));
const DumpComapnies = lazy(() => import("./pages/dumpcompanies/DumpComapnies"));

let routes = [
  {
    path: "/companies",
    element: <Companies />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/reports",
    element: <Reports />,
  },
  {
    path: "/roles",
    element: <Roles />,
  },
  {
    path: "/blogs",
    element: <Blog />,
  },
  {
    path: "/create-blog",
    element: <CreateBlog />,
  },
  {
    path: "/award",
    element: <Award />,
  },
  {
    path: "/award/create-blog/:type",
    element: <CreateAwardBlog />,
  },
  {
    path: "/award/create-blog/:type/:id",
    element: <CreateAwardBlog />,
  },
  {
    path: "/edit-blog/:id",
    element: <EditBlog />,
  },
  {
    path: "/industries-category",
    element: <Industries />,
  },
  {
    path: "/reactions",
    element: <Reactions />,
  },
  {
    path: "/reactions/create-reactions",
    element: <CreateReactions />,
  },
  {
    path: "/reactions/edit-reactions/:id",
    element: <EditReactions />,
  },

  {
    path: "/moderation",
    element: <Moderation />,
  },
  {
    path: "/",
    element: <Dash />,
  },
  {
    path: "/companies/:slug",
    element: <Profil />,
  },
  {
    path: "/users/:slug",
    element: <Profil />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/creat",
    element: <Creat />,
  },
  {
    path: "/create/:username",
    element: <Creat />,
  },
  {
    path: "/ambassador",
    element: <AmbassadorPage />,
  },
  {
    path: "/admins",
    element: <Admins />,
  },
  {
    path: "/reviewpage",
    element: <ReviewPage />,
  },
  {
    path: "/support",
    element: <Support />,
  },
  {
    path: "/Qa",
    element: <Qa />,
  },
  {
    path: "/events",
    element: <Events />,
  },
  {
    path: "/events/create",
    element: <CreateEvent />,
  },
  {
    path: "/events/:id/edit",
    element: <EditEvent />,
  },
  {
    path: "/events/:id/view",
    element: <EventView />,
  },
  {
    path: "/dump-companies",
    element: <DumpComapnies />,
  },
];

function App() {
  const { auth } = useSelector((state) => state.adminActions);

  const { message } = useSelector((state) => state.toastifyListener);

  useEffect(() => {
    if (!message.message) return;
    toast(message.message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: message.type,
    });
  }, [message]);

  if (auth) {
    return (
      <ThemeProvider theme={theme}>
        <Routes>
          {routes.map((index) => {
            return (
              <Route
                key={index.path}
                path={index.path}
                element={
                  <DashboardLayout>
                    <Suspense>{index.element}</Suspense>
                  </DashboardLayout>
                }
              />
            );
          })}
        </Routes>
        <>
          <ToastContainer />
        </>
      </ThemeProvider>
    );
  } else {
    return (
      <Suspense>
        <Login />
      </Suspense>
    );
  }
}

export default App;
