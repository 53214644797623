import { createSlice } from "@reduxjs/toolkit";


const priceRanges = createSlice({
  name: "priceRanges",
  initialState: {
    prices: [
        {
            value: "",
            label: "All"
        },
        {
            value: "0",
            label: "$"
        },
        {
            value: "1",
            label: "$$"
        },
        {
            value: "2",
            label: "$$$"
        },
        {
            value: "3",
            label: "$$$$"
        },
    ],
    service_types: [
        {
            value: "",
            label: "All"
        },
        {
            value: "1",
            label: "Fully Staffed"
        },
        {
            value: "2",
            label: "Hybrid"
        },
        {
            value: "3",
            label: "Fully Self-service"
        },
    ]
  },
})


export default priceRanges.reducer