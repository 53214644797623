import axios from "axios";


const api = process.env.REACT_APP_API_URL;
const version = process.env.REACT_APP_API_VERSION;


const api_url = api + version;

export const publicAxios = axios.create({
  baseURL: api_url,
  headers: {
    "Content-Type": "application/json",
  },
});
