import { createSlice } from "@reduxjs/toolkit";




const navigation = createSlice({
  name:"navigation",
  initialState: {
    route: []
  },
  reducers: {
    fetchData : (state, action) => {
      
      
      state.route = action.payload
    }
  }
})

export const { fetchData } = navigation.actions;

export default navigation.reducer