import { createSlice } from "@reduxjs/toolkit";




const toastifyListener = createSlice({
  name: "toastifyListener",
  initialState: {
    message:{
      type:"",
      message: ""
    }
  },
  reducers: {
    triggerToastify: (state, action) => {
      state.message = {
        type: action.payload.type,
        message: action.payload.message
      }
    }
  }
})

export const { triggerToastify } = toastifyListener.actions;

export default toastifyListener.reducer