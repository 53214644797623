import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { privateAxios } from "../lib/privateAxios";

export const getUserInfo = createAsyncThunk("userInfo", async () => {
  const token = localStorage.getItem("access");


  if (token) {
    let res = await privateAxios.get("/admin/user");


    if (res.status == 200) {
      return res.data;
    }
  }
});


const adminActions = createSlice({
  name: "adminActions",
  initialState: {
    auth: localStorage.getItem("access") ? true : false,
    userInfo: {}
  },
  reducers: {
    setAdminVerify: (state,action) =>{
      state.auth = action.payload
    },
  },
  extraReducers: {
    [getUserInfo.pending]: (state) => {
      return { ...state, pending: true };
    },
    [getUserInfo.fulfilled]: (state, { payload }) => {
      return { ...state, pending: false, userInfo: payload };
    },
    [getUserInfo.rejected]: (state, { payload }) => {
      return { ...state, pending: false, error: payload };
    },
  },
})

export const { setAdminVerify } = adminActions.actions;

export default adminActions.reducer