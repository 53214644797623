import { createSlice } from "@reduxjs/toolkit";

export const countries = createSlice({
  name: "countries",
  initialState: {
    countries: [
      {
        value: "1",
        label: "Afghanistan",
      },
      {
        value: "2",
        label: "Aland Islands",
      },
      {
        value: "3",
        label: "Albania",
      },
      {
        value: "4",
        label: "Algeria",
      },
      {
        value: "5",
        label: "American Samoa",
      },
      {
        value: "6",
        label: "Andorra",
      },
      {
        value: "7",
        label: "Angola",
      },
      {
        value: "8",
        label: "Anguilla",
      },
      {
        value: "9",
        label: "Antarctica",
      },
      {
        value: "10",
        label: "Antigua and Barbuda",
      },
      {
        value: "11",
        label: "Argentina",
      },
      {
        value: "12",
        label: "Armenia",
      },
      {
        value: "13",
        label: "Aruba",
      },
      {
        value: "14",
        label: "Australia",
      },
      {
        value: "15",
        label: "Austria",
      },
      {
        value: "16",
        label: "Azerbaijan",
      },
      {
        value: "17",
        label: "Bahamas",
      },
      {
        value: "18",
        label: "Bahrain",
      },
      {
        value: "19",
        label: "Bangladesh",
      },
      {
        value: "20",
        label: "Barbados",
      },
      {
        value: "21",
        label: "Belarus",
      },
      {
        value: "22",
        label: "Belgium",
      },
      {
        value: "23",
        label: "Belize",
      },
      {
        value: "24",
        label: "Benin",
      },
      {
        value: "25",
        label: "Bermuda",
      },
      {
        value: "26",
        label: "Bhutan",
      },
      {
        value: "27",
        label: "Bolivia, Plurinational State of",
      },
      {
        value: "28",
        label: "Bonaire, Sint Eustatius and Saba",
      },
      {
        value: "29",
        label: "Bosnia and Herzegovina",
      },
      {
        value: "30",
        label: "Botswana",
      },
      {
        value: "31",
        label: "Bouvet Island",
      },
      {
        value: "32",
        label: "Brazil",
      },
      {
        value: "33",
        label: "British Indian Ocean Territory",
      },
      {
        value: "34",
        label: "Brunei Darussalam",
      },
      {
        value: "35",
        label: "Bulgaria",
      },
      {
        value: "36",
        label: "Burkina Faso",
      },
      {
        value: "37",
        label: "Burundi",
      },
      {
        value: "38",
        label: "Cambodia",
      },
      {
        value: "39",
        label: "Cameroon",
      },
      {
        value: "40",
        label: "Canada",
      },
      {
        value: "41",
        label: "Cape Verde",
      },
      {
        value: "42",
        label: "Cayman Islands",
      },
      {
        value: "43",
        label: "Central African Republic",
      },
      {
        value: "44",
        label: "Chad",
      },
      {
        value: "45",
        label: "Chile",
      },
      {
        value: "46",
        label: "China",
      },
      {
        value: "47",
        label: "Christmas Island",
      },
      {
        value: "48",
        label: "Cocos (Keeling) Islands",
      },
      {
        value: "49",
        label: "Colombia",
      },
      {
        value: "50",
        label: "Comoros",
      },
      {
        value: "51",
        label: "Congo",
      },
      {
        value: "52",
        label: "Congo, The Democratic Republic of the",
      },
      {
        value: "53",
        label: "Cook Islands",
      },
      {
        value: "54",
        label: "Costa Rica",
      },
      {
        value: "55",
        label: "Cote d'Ivoire",
      },
      {
        value: "56",
        label: "Croatia",
      },
      {
        value: "57",
        label: "Cuba",
      },
      {
        value: "58",
        label: "Curacao",
      },
      {
        value: "59",
        label: "Cyprus",
      },
      {
        value: "60",
        label: "Czech Republic",
      },
      {
        value: "61",
        label: "Denmark",
      },
      {
        value: "62",
        label: "Djibouti",
      },
      {
        value: "63",
        label: "Dominica",
      },
      {
        value: "64",
        label: "Dominican Republic",
      },
      {
        value: "65",
        label: "Ecuador",
      },
      {
        value: "66",
        label: "Egypt",
      },
      {
        value: "67",
        label: "El Salvador",
      },
      {
        value: "68",
        label: "Equatorial Guinea",
      },
      {
        value: "69",
        label: "Eritrea",
      },
      {
        value: "70",
        label: "Estonia",
      },
      {
        value: "71",
        label: "Ethiopia",
      },
      {
        value: "72",
        label: "Falkland Islands 'Malvinas')",
      },
      {
        value: "73",
        label: "Faroe Islands",
      },
      {
        value: "74",
        label: "Fiji",
      },
      {
        value: "75",
        label: "Finland",
      },
      {
        value: "76",
        label: "France",
      },
      {
        value: "77",
        label: "French Guiana",
      },
      {
        value: "78",
        label: "French Polynesia",
      },
      {
        value: "79",
        label: "French Southern Territories",
      },
      {
        value: "80",
        label: "Gabon",
      },
      {
        value: "81",
        label: "Gambia",
      },
      {
        value: "82",
        label: "Georgia",
      },
      {
        value: "83",
        label: "Germany",
      },
      {
        value: "84",
        label: "Ghana",
      },
      {
        value: "85",
        label: "Gibraltar",
      },
      {
        value: "86",
        label: "Greece",
      },
      {
        value: "87",
        label: "Greenland",
      },
      {
        value: "88",
        label: "Grenada",
      },
      {
        value: "89",
        label: "Guadeloupe",
      },
      {
        value: "90",
        label: "Guam",
      },
      {
        value: "91",
        label: "Guatemala",
      },
      {
        value: "92",
        label: "Guernsey",
      },
      {
        value: "93",
        label: "Guinea",
      },
      {
        value: "94",
        label: "Guinea-Bissau",
      },
      {
        value: "95",
        label: "Guyana",
      },
      {
        value: "96",
        label: "Haiti",
      },
      {
        value: "97",
        label: "Heard Island and McDonald Islands",
      },
      {
        value: "98",
        label: "Holy See (Vatican City State)",
      },
      {
        value: "99",
        label: "Honduras",
      },
      {
        value: "100",
        label: "Hong Kong",
      },
      {
        value: "101",
        label: "Hungary",
      },
      {
        value: "102",
        label: "Iceland",
      },
      {
        value: "103",
        label: "India",
      },
      {
        value: "104",
        label: "Indonesia",
      },
      {
        value: "105",
        label: "Iran, Islamic Republic of",
      },
      {
        value: "106",
        label: "Iraq",
      },
      {
        value: "107",
        label: "Ireland",
      },
      {
        value: "108",
        label: "Isle of Man",
      },
      {
        value: "109",
        label: "Israel",
      },
      {
        value: "110",
        label: "Italy",
      },
      {
        value: "111",
        label: "Jamaica",
      },
      {
        value: "112",
        label: "Japan",
      },
      {
        value: "113",
        label: "Jersey",
      },
      {
        value: "114",
        label: "Jordan",
      },
      {
        value: "115",
        label: "Kazakhstan",
      },
      {
        value: "116",
        label: "Kenya",
      },
      {
        value: "117",
        label: "Kiribati",
      },
      {
        value: "118",
        label: "Korea, Democratic People's Republic of",
      },
      {
        value: "119",
        label: "Korea, Republic of",
      },
      {
        value: "120",
        label: "Kuwait",
      },
      {
        value: "121",
        label: "Kyrgyzstan",
      },
      {
        value: "122",
        label: "Lao People's Democratic Republic",
      },
      {
        value: "123",
        label: "Latvia",
      },
      {
        value: "124",
        label: "Lebanon",
      },
      {
        value: "125",
        label: "Lesotho",
      },
      {
        value: "126",
        label: "Liberia",
      },
      {
        value: "127",
        label: "Libya",
      },
      {
        value: "128",
        label: "Liechtenstein",
      },
      {
        value: "129",
        label: "Lithuania",
      },
      {
        value: "130",
        label: "Luxembourg",
      },
      {
        value: "131",
        label: "Macao",
      },
      {
        value: "132",
        label: "Macedonia, Republic of",
      },
      {
        value: "133",
        label: "Madagascar",
      },
      {
        value: "134",
        label: "Malawi",
      },
      {
        value: "135",
        label: "Malaysia",
      },
      {
        value: "136",
        label: "Maldives",
      },
      {
        value: "137",
        label: "Mali",
      },
      {
        value: "138",
        label: "Malta",
      },
      {
        value: "139",
        label: "Marshall Islands",
      },
      {
        value: "140",
        label: "Martinique",
      },
      {
        value: "141",
        label: "Mauritania",
      },
      {
        value: "142",
        label: "Mauritius",
      },
      {
        value: "143",
        label: "Mayotte",
      },
      {
        value: "144",
        label: "Mexico",
      },
      {
        value: "145",
        label: "Micronesia, Federated States of",
      },
      {
        value: "146",
        label: "Moldova, Republic of",
      },
      {
        value: "147",
        label: "Monaco",
      },
      {
        value: "148",
        label: "Mongolia",
      },
      {
        value: "149",
        label: "Montenegro",
      },
      {
        value: "150",
        label: "Montserrat",
      },
      {
        value: "151",
        label: "Morocco",
      },
      {
        value: "152",
        label: "Mozambique",
      },
      {
        value: "153",
        label: "Myanmar",
      },
      {
        value: "154",
        label: "Namibia",
      },
      {
        value: "155",
        label: "Nauru",
      },
      {
        value: "156",
        label: "Nepal",
      },
      {
        value: "157",
        label: "Netherlands",
      },
      {
        value: "158",
        label: "New Caledonia",
      },
      {
        value: "159",
        label: "New Zealand",
      },
      {
        value: "160",
        label: "Nicaragua",
      },
      {
        value: "161",
        label: "Niger",
      },
      {
        value: "162",
        label: "Nigeria",
      },
      {
        value: "163",
        label: "Niue",
      },
      {
        value: "164",
        label: "Norfolk Island",
      },
      {
        value: "165",
        label: "Northern Mariana Islands",
      },
      {
        value: "166",
        label: "Norway",
      },
      {
        value: "167",
        label: "Oman",
      },
      {
        value: "168",
        label: "Pakistan",
      },
      {
        value: "169",
        label: "Palau",
      },
      {
        value: "170",
        label: "Palestine",
      },
      {
        value: "171",
        label: "Panama",
      },
      {
        value: "172",
        label: "Papua New Guinea",
      },
      {
        value: "173",
        label: "Paraguay",
      },
      {
        value: "174",
        label: "Peru",
      },
      {
        value: "175",
        label: "Philippines",
      },
      {
        value: "176",
        label: "Pitcairn",
      },
      {
        value: "177",
        label: "Poland",
      },
      {
        value: "178",
        label: "Portugal",
      },
      {
        value: "179",
        label: "Puerto Rico",
      },
      {
        value: "180",
        label: "Qatar",
      },
      {
        value: "181",
        label: "Reunion",
      },
      {
        value: "182",
        label: "Romania",
      },
      {
        value: "183",
        label: "Russian Federation",
      },
      {
        value: "184",
        label: "Rwanda",
      },
      {
        value: "185",
        label: "Saint Barthelemy",
      },
      {
        value: "186",
        label: "Saint Helena, Ascension and Tristan da Cunha",
      },
      {
        value: "187",
        label: "Saint Kitts and Nevis",
      },
      {
        value: "188",
        label: "Saint Lucia",
      },
      {
        value: "189",
        label: "Saint Martin (French part)",
      },
      {
        value: "190",
        label: "Saint Pierre and Miquelon",
      },
      {
        value: "191",
        label: "Saint Vincent and the Grenadines",
      },
      {
        value: "192",
        label: "Samoa",
      },
      {
        value: "193",
        label: "San Marino",
      },
      {
        value: "194",
        label: "Sao Tome and Principe",
      },
      {
        value: "195",
        label: "Saudi Arabia",
      },
      {
        value: "196",
        label: "Senegal",
      },
      {
        value: "197",
        label: "Serbia",
      },
      {
        value: "198",
        label: "Seychelles",
      },
      {
        value: "199",
        label: "Sierra Leone",
      },
      {
        value: "200",
        label: "Singapore",
      },
      {
        value: "201",
        label: "Sint Maarten (Dutch part)",
      },
      {
        value: "202",
        label: "Slovakia",
      },
      {
        value: "203",
        label: "Slovenia",
      },
      {
        value: "204",
        label: "Solomon Islands",
      },
      {
        value: "205",
        label: "Somalia",
      },
      {
        value: "206",
        label: "South Africa",
      },
      {
        value: "207",
        label: "South Georgia and the South Sandwich Islands",
      },
      {
        value: "208",
        label: "Spain",
      },
      {
        value: "209",
        label: "Sri Lanka",
      },
      {
        value: "210",
        label: "Sudan",
      },
      {
        value: "211",
        label: "Surilabel",
      },
      {
        value: "212",
        label: "South Sudan",
      },
      {
        value: "213",
        label: "Svalbard and Jan Mayen",
      },
      {
        value: "214",
        label: "Swaziland",
      },
      {
        value: "215",
        label: "Sweden",
      },
      {
        value: "216",
        label: "Switzerland",
      },
      {
        value: "217",
        label: "Syrian Arab Republic",
      },
      {
        value: "218",
        label: "Taiwan, Province of China",
      },
      {
        value: "219",
        label: "Tajikistan",
      },
      {
        value: "220",
        label: "Tanzania, United Republic of",
      },
      {
        value: "221",
        label: "Thailand",
      },
      {
        value: "222",
        label: "Timor-Leste",
      },
      {
        value: "223",
        label: "Togo",
      },
      {
        value: "224",
        label: "Tokelau",
      },
      {
        value: "225",
        label: "Tonga",
      },
      {
        value: "226",
        label: "Trinvaluead and Tobago",
      },
      {
        value: "227",
        label: "Tunisia",
      },
      {
        value: "228",
        label: "Turkey",
      },
      {
        value: "229",
        label: "Turkmenistan",
      },
      {
        value: "230",
        label: "Turks and Caicos Islands",
      },
      {
        value: "231",
        label: "Tuvalu",
      },
      {
        value: "232",
        label: "Uganda",
      },
      {
        value: "233",
        label: "Ukraine",
      },
      {
        value: "234",
        label: "United Arab Emirates",
      },
      {
        value: "235",
        label: "United Kingdom",
      },
      {
        value: "236",
        label: "United States",
      },
      {
        value: "237",
        label: "United States Minor Outlying Islands",
      },
      {
        value: "238",
        label: "Uruguay",
      },
      {
        value: "239",
        label: "Uzbekistan",
      },
      {
        value: "240",
        label: "Vanuatu",
      },
      {
        value: "241",
        label: "Venezuela, Bolivarian Republic of",
      },
      {
        value: "242",
        label: "Viet Nam",
      },
      {
        value: "243",
        label: "Virgin Islands, British",
      },
      {
        value: "244",
        label: "Virgin Islands, U.S.",
      },
      {
        value: "245",
        label: "Wallis and Futuna",
      },
      {
        value: "246",
        label: "Yemen",
      },
      {
        value: "247",
        label: "Zambia",
      },
      {
        value: "248",
        label: "Zimbabwe",
      },
    ],
    states: [
      {
        value: "0",
        label: "Alaska",
      },
      {
        value: "1",
        label: "Alabama",
      },
      {
        value: "2",
        label: "Arkansas",
      },

      {
        value: "4",
        label: "Arizona",
      },
      {
        value: "5",
        label: "California",
      },
      {
        value: "6",
        label: "Colorado",
      },
      {
        value: "7",
        label: "Connecticut",
      },
      {
        value: "9",
        label: "Delaware",
      },
      {
        value: "11",
        label: "Georgia",
      },
      {
        value: "13",
        label: "Hawaii",
      },
      {
        value: "14",
        label: "Iowa",
      },
      {
        value: "16",
        label: "Illinois",
      },
      {
        value: "17",
        label: "Indiana",
      },
      {
        value: "18",
        label: "Kansas",
      },
      {
        value: "19",
        label: "Kentucky",
      },
      {
        value: "20",
        label: "Louisiana",
      },
      {
        value: "21",
        label: "Massachusetts",
      },
      {
        value: "22",
        label: "Maryland",
      },
      {
        value: "23",
        label: "Maine",
      },
      {
        value: "24",
        label: "Michigan",
      },
      {
        value: "25",
        label: "Minnesota",
      },
      {
        value: "26",
        label: "Missouri",
      },
    
      {
        value: "28",
        label: "Mississippi",
      },
      {
        value: "29",
        label: "Montana",
      },
     
      {
        value: "31",
        label: "North Carolina",
      },
      {
        value: "32",
        label: "North Dakota",
      },
      {
        value: "33",
        label: "Nebraska",
      },
      {
        value: "34",
        label: "New Hampshire",
      },
      {
        value: "35",
        label: "New Jersey",
      },
      {
        value: "36",
        label: "New Mexico",
      },
      {
        value: "37",
        label: "Nevada",
      },
      {
        value: "38",
        label: "New York",
      },
      {
        value: "39",
        label: "Ohio",
      },
      {
        value: "40",
        label: "Oklahoma",
      },
      {
        value: "41",
        label: "Oregon",
      },
      {
        value: "42",
        label: "Pennsylvania",
      },
      {
        value: "43",
        label: "Puerto Rico",
      },
      {
        value: "44",
        label: "Rhode Island",
      },
      {
        value: "45",
        label: "South Carolina",
      },
      {
        value: "46",
        label: "South Dakota",
      },
      {
        value: "47",
        label: "Tennessee",
      },
      {
        value: "48",
        label: "Texas",
      },
      {
        value: "49",
        label: "Utah",
      },
      {
        value: "50",
        label: "Virginia",
      },
      {
        value: "51",
        label: "Virgin Islands",
      },
      {
        value: "52",
        label: "Vermont",
      },
      {
        value: "53",
        label: "Washington",
      },
      {
        value: "54",
        label: "Wisconsin",
      },
      {
        value: "55",
        label: "West Virginia",
      },
      {
        value: "56",
        label: "Wyoming",
      },
    ],
    selectedCities: []
  },
  reducers: {
    setVisiterCountry: (state, action) => {
      state.visiterCountry = action.payload;
    },
    setSelectedCountry: (state,action) => {
      state.selectedCities = action.payload
    },
    setSelectedCities: (state, action) => {
      let temp = [...state.selectedCities, ...action.payload]
      temp = new Set(temp)
      state.selectedCities = [...temp]
    },

    resetAllSelectedCities: (state) => {
     
      state.selectedCities = []

    },
    removeCountrysCity: (state, action) => {

      
      let y = state.selectedCities.filter(index => index !== action.payload)
      
      
      state.selectedCities = y
    }
  },
});

export const { setVisiterCountry, setSelectedCities, setSelectedCountry, resetAllSelectedCities, removeCountrysCity } = countries.actions;
export default countries.reducer;
